<template>
    <div class="flexPage darkPage">
        <div class="flexHead">
            <navbar :params="pageInfo" />
        </div>

        <div class="lang-cell" @click="onSelectLanguage(item)" v-for="item in lang.actions" :key="item.name">
            <span>{{ item.name }}</span>
            <van-icon v-if="vLanguage === item.value" name="success" color="#3861FB" size="20" />
        </div>
    </div>
</template>
<script>
import navbar from "@/views/modules/navbar.vue";
export default {
    data() {
        return {
            pageInfo: {
                title: '',
                customTitle: this.$t('assets.personal.changeLang'),
                navbarStyle: 'normal',
                backname: "setting"
            },
            lang: {
                show: false,
                actions: [
                    { name: '繁体中文', value: 'zh-CN' },
                    { name: 'English', value: 'en-US' }
                ]
            },
            vLanguage: localStorage.getItem('language') ? localStorage.getItem('language') : 'en-US',
        }
    },
    components: { navbar },
    computed: {
        showLang() {
            switch (this.vLanguage) {
                case "zh-CN":
                    return "繁体中文";
                case "en-US":
                    return "English";
                default:
                    return "English";
            }
        },
    },
    methods: {
        onSelectLanguage(item) {
            this.lang.show = false;
            switch (item.name) {
                case "繁体中文":
                    this.vLanguage = 'zh-CN';
                    break;
                case "English":
                    this.vLanguage = 'en-US';
                    break;
                default:
                    this.vLanguage = 'en-US';
                    break;
            }
            localStorage.setItem("language", this.vLanguage);
            this.$router.push({ name: "home" });
            location.reload();
        },
    }
}
</script>

<style>
.lang-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5445vw 5.0891vw;
    height: 11.1959vw;
    border-bottom: 1px solid #EFF0F2;
}
</style>